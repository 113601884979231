import axios from "axios";
import { axiosInstance, BaseURL, TOKEN_PREFIX } from "./AxiosHelper";
import moment from "moment";
import fileDownload from "js-file-download";
import { serialize } from "object-to-formdata";

const toSentenceCase = (string) => {
	// return string.charAt(0).toUpperCase() + string.slice(1);
	return string
		.split(" ")
		.map((string) =>
			string ? string.charAt(0).toUpperCase() + string.slice(1) : null
		)
		.filter((string) => string)
		.join(" ");
};

export const appliedScheme = (fieldData, productScheme, old_obj = {}) => {
	console.log(fieldData);
	console.log(productScheme);
	const calculatedPrice =
		parseFloat(fieldData?.unit_units) *
		parseFloat(fieldData.quantity) *
		parseFloat(fieldData.price); // price before taxation.

	let freeProductObj = {};
	const obj = old_obj;
	const schemetype = productScheme?.scheme_type;
	const selector_type = productScheme.selector_type;
	const apply_type = productScheme.apply_type;
	const max_quantity = parseInt(productScheme?.max_quantity);
	const min_quantity = parseInt(productScheme?.min_quantity);
	const freeQuantity = productScheme?.free_quantity;

	console.log(calculatedPrice);

	const calculate_total_price = (discount_amt) => {
		var discountAmt = discount_amt;
		var taxableValue = calculatedPrice - discountAmt;
		var totalPrice = parseFloat(taxableValue);

		console.log(discountAmt, taxableValue, totalPrice);

		obj.discount_percent =
			(parseFloat(discountAmt) / calculatedPrice) * 100;
		obj.discountAmt = parseFloat(discountAmt).toFixed(2);
		obj.price = parseFloat(totalPrice).toFixed(2);
		obj.free_quantity = freeQuantity;
		obj.free_unit = productScheme?.free_unit;
	};

	const selector_type_fn = (selector_type, apply_type_fn) => {
		switch (selector_type) {
			// Range
			case 1:
				if (
					fieldData.quantity >= min_quantity &&
					fieldData.quantity <= max_quantity
				) {
					apply_type_fn();
				}
				break;
			// Greater Than Min
			case 2:
				if (fieldData.quantity >= min_quantity) {
					apply_type_fn();
				}
				break;
			// Greater Than Max
			case 3:
				if (fieldData.quantity >= max_quantity) {
					apply_type_fn();
				}
				break;
		}
	};

	switch (schemetype) {
		case 1:
			//discount
			const discountType = productScheme?.discount_type;

			switch (discountType) {
				case 1:
					//value

					// -------------------------old code starting -----------------------------
					// var discountAmt = productScheme?.discount_value;
					// var taxableValue = calculatedPrice - discountAmt;
					// // var gstAmt = selectedData.tax_percent
					// // 	? parseFloat(taxableValue) *
					// // 	(+selectedData.tax_percent / 100)
					// // 	: 0;
					// // var totalPrice =
					// // 	parseFloat(taxableValue) + parseFloat(gstAmt);
					// var totalPrice =
					// 	parseFloat(taxableValue);
					// obj.discount_percent = (parseFloat(discountAmt) / calculatedPrice) * 100;
					// obj.discountAmt =
					// 	parseFloat(discountAmt).toFixed(2);
					// obj.price = parseFloat(totalPrice).toFixed(2);
					// // obj.taxableValue =
					// // 	parseFloat(taxableValue).toFixed(2);
					// // obj.gstAmt = parseFloat(gstAmt).toFixed(2);
					// // Dispatch(dispatch, addProductsToList, {
					// // 	...obj,
					// // 	freeItem: false,
					// // });
					// -------------------------old code ending -----------------------------

					const apply_type_fn = () => {
						switch (apply_type) {
							// Direct
							case 1:
								var discount_amt =
									productScheme?.discount_value;
								calculate_total_price(discount_amt);
								break;
							// Quantity Multiple
							case 2:
								var discount_amt =
									fieldData.quantity *
									productScheme?.discount_value;
								calculate_total_price(discount_amt);
								break;
							// Multiples of Min
							case 3:
								var discount_amt =
									parseInt(
										parseInt(fieldData.quantity) /
											parseInt(min_quantity)
									) * parseInt(productScheme?.discount_value);
								calculate_total_price(discount_amt);
								break;
						}
					};

					selector_type_fn(selector_type, apply_type_fn);
					break;

				case 2:
					// this code will be changed.
					//percentage

					// -------------------------old code starting -----------------------------
					// const discountPercentage =
					// 	productScheme?.discount_percentage
					// 		? productScheme?.discount_percentage
					// 		: 0;

					// var discountAmt = discountPercentage
					// 	? parseFloat(calculatedPrice) *
					// 	(parseInt(discountPercentage) / 100)
					// 	: 0;
					// var taxableValue = calculatedPrice - discountAmt;
					// // var gstAmt = selectedData.tax_percent
					// // 	? parseFloat(taxableValue) *
					// // 	(parseInt(selectedData.tax_percent) / 100)
					// // 	: 0;
					// // var totalPrice =
					// // 	parseFloat(taxableValue) + parseFloat(gstAmt);
					// var totalPrice =
					// 	parseFloat(taxableValue);

					// obj.discount_percent = discountPercentage;
					// obj.discountAmt =
					// 	parseFloat(discountAmt).toFixed(2);
					// obj.price = parseFloat(totalPrice).toFixed(2);
					// // obj.taxableValue =
					// // 	parseFloat(taxableValue).toFixed(2);
					// // obj.gstAmt = parseFloat(gstAmt).toFixed(2);
					// // Dispatch(dispatch, addProductsToList, {
					// // 	...obj,
					// // 	freeItem: false,
					// // });
					// -------------------------old code ending -----------------------------

					var apply_type_fn_for_discount_percentage = () => {
						switch (apply_type) {
							// Direct
							case 1:
								var discountPercentage =
									productScheme?.discount_percentage
										? productScheme?.discount_percentage
										: 0;
								var discount_amt = discountPercentage
									? parseFloat(calculatedPrice) *
									  (parseInt(discountPercentage) / 100)
									: 0;
								calculate_total_price(discount_amt);
								break;
							// Quantity Multiple
							case 2:
								break;
							// Multiples of Min
							case 3:
								break;
						}
					};
					selector_type_fn(
						selector_type,
						apply_type_fn_for_discount_percentage
					);
					break;
			}
			break;
		case 2:
			// free product

			// -------------------------old code starting -----------------------------

			// const freeQuantity = productScheme?.free_quantity;
			// const discountPercentage =
			// 	productScheme?.discount_percentage
			// 		? productScheme?.discount_percentage
			// 		: 0;
			// var discountAmt = discountPercentage
			// 	? parseFloat(calculatedPrice) *
			// 	(parseInt(discountPercentage) / 100)
			// 	: 0;
			// var taxableValue = calculatedPrice - discountAmt;
			// // var gstAmt = selectedData.tax_percent
			// // 	? parseFloat(taxableValue) *
			// // 	(parseInt(selectedData.tax_percent) / 100)
			// // 	: 0;
			// // var totalPrice =
			// // 	parseFloat(taxableValue) + parseFloat(gstAmt);
			// var totalPrice =
			// 	parseFloat(taxableValue);

			// obj.discount_percent = parseFloat(discountPercentage).toFixed(2);
			// obj.discountAmt = parseFloat(discountAmt).toFixed(2);
			// obj.price = parseFloat(totalPrice).toFixed(2);
			// // obj.taxableValue = parseFloat(taxableValue).toFixed(2);
			// // obj.gstAmt = parseFloat(gstAmt).toFixed(2);
			// if (
			// 	productScheme?.free_product?.id ==
			// 	selectedData.product.id
			// ) {
			// 	obj.free_quantity = freeQuantity;
			// 	obj.free_unit = productScheme?.free_unit;
			// }
			// // Dispatch(dispatch, addProductsToList, { ...obj });

			// freeProductObj = {
			// 	...productScheme?.free_product,
			// 	product: productScheme?.product,
			// 	freeItem: true,
			// 	discount_percent: 0,
			// 	free_quantity: freeQuantity,
			// 	free_unit: productScheme?.free_unit,
			// 	uniqueId: obj.uniqueId,
			// 	dodelete: false,
			// 	showCancelIcon: false,
			// };

			// // if (freeProduct?.id != selectedData.product.id) {
			// // 	// Dispatch(dispatch, addProductsToList, { ...obj, freeProduct });
			// // 	Dispatch(dispatch, addProductsToList, {
			// // 		...freeProduct,
			// // 	});
			// // }

			// -------------------------old code ended -----------------------------

			const discountPercentage = productScheme?.discount_percentage
				? productScheme?.discount_percentage
				: 0;
			var discountAmt = discountPercentage
				? parseFloat(calculatedPrice) *
				  (parseInt(discountPercentage) / 100)
				: 0;
			var taxableValue = calculatedPrice - discountAmt;
			var totalPrice = parseFloat(taxableValue);

			obj.discount_percent = parseFloat(discountPercentage).toFixed(2);
			obj.discountAmt = parseFloat(discountAmt).toFixed(2);
			obj.price = parseFloat(totalPrice).toFixed(2);

			const apply_type_fn = () => {
				switch (apply_type) {
					// Direct
					case 1:
						obj.free_quantity = freeQuantity;
						obj.free_unit = productScheme?.free_unit;
						break;
					// Quantity Multiple
					case 2:
						obj.free_quantity = fieldData.quantity * freeQuantity;
						obj.free_unit = productScheme?.free_unit;
						break;
					// Multiples of Min
					case 3:
						obj.free_quantity =
							parseInt(
								parseInt(fieldData.quantity) /
									parseInt(min_quantity)
							) * parseInt(freeQuantity);
						obj.free_unit = productScheme?.free_unit;
						break;
				}
			};
			if (productScheme?.free_product?.id == fieldData.product_id) {
				switch (selector_type) {
					// Range
					case 1:
						if (
							fieldData.quantity >= min_quantity &&
							fieldData.quantity <= max_quantity
						) {
							apply_type_fn();
						}
						break;
					// Greater Than Min
					case 2:
						if (fieldData.quantity >= min_quantity) {
							apply_type_fn();
						}
						break;
					// Greater Than Max
					case 3:
						if (fieldData.quantity >= max_quantity) {
							apply_type_fn();
						}
						break;
				}
			} else {
				freeProductObj = {
					...productScheme?.free_product,
					product: productScheme?.product,
					freeItem: true,
					discount_percent: 0,
					free_unit: productScheme?.free_unit,
					uniqueId: obj.uniqueId,
					dodelete: false,
					showCancelIcon: false,
				};
				const apply_type_fn = () => {
					switch (apply_type) {
						// Direct
						case 1:
							freeProductObj.free_quantity = freeQuantity;
							break;
						// Quantity Multiple
						case 2:
							freeProductObj.free_quantity =
								fieldData.quantity * freeQuantity;
							break;
						// Multiples of Min
						case 3:
							freeProductObj.free_quantity =
								parseInt(
									parseInt(fieldData.quantity) /
										parseInt(min_quantity)
								) * parseInt(freeQuantity);
							break;
					}
				};

				selector_type_fn(selector_type, apply_type_fn);
			}

			break;

		default:
			var discountAmt = 0;
			var taxableValue = calculatedPrice - discountAmt;
			// var gstAmt = selectedData.tax_percent
			// 	? parseFloat(taxableValue) *
			// 	(+selectedData.tax_percent / 100)
			// 	: 0;
			// var totalPrice =
			// 	parseFloat(taxableValue) + parseFloat(gstAmt);
			var totalPrice = parseFloat(taxableValue);
			obj.discount_percent = 0;
			obj.discountAmt = parseFloat(discountAmt).toFixed(2);
			obj.price = parseFloat(totalPrice).toFixed(2);
			// obj.taxableValue = parseFloat(taxableValue).toFixed(2);
			// obj.gstAmt = parseFloat(gstAmt).toFixed(2);
			// Dispatch(dispatch, addProductsToList, {
			// 	...obj,
			// 	freeItem: false,
			// });
			break;
	}

	console.log({ obj, freeProductObj });

	return { obj, freeProductObj };
};

const addParams = (payload) => {
	var data = payload;
	let params = {};
	for (const k in data) {
		if (Object.hasOwnProperty.call(data, k)) {
			if (k === "currentSort" || k === "sortOrder") {
				params.ordering = data.sortOrder + data.currentSort;
			} else {
				if (data[k] !== "" && k !== null && k != "no_of_pages") {
					if (k == "start_date") {
						params[k] = moment(data[k]).format("YYYY-MM-DD");
					} else if (k == "end_date") {
						params[k] = moment(data[k]).format("YYYY-MM-DD");
					} else {
						params[k] = data[k];
					}
				}
			}
		}
	}
	return params;
};

// utils.js
export function debounce(func, timeout = 1000) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}


const simplePost = (url, data) => {
	return axios
		.post(BaseURL + url, data)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;

			return response;
		})
		.catch((err) => {
			throw err.response;
		});
};
// const VendorLogin = (url, data) => {
//   return axios
//     .post(BaseURL + url, data)
//     .then((response) => {
//       if (response.status === 400 || response.status === 500)
//         throw response.data;
//       axiosInstance.defaults.headers["Authorization"] =
//         TOKEN_PREFIX + response.data.access_token;

//       return response;
//     })
//     .catch((err) => {
//       throw err.response.data;
//     });
// };
const postLogin = (url, data) => {
	return axios
		.post(BaseURL + url, data)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;

			axiosInstance.defaults.headers["Authorization"] =
				TOKEN_PREFIX + response.data.tokens.access;
			return response;
		})
		.catch((err) => {
			throw err.response;
		});
};
const postLogout = (url, data) => {
	return axiosInstance
		.post(url, data)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response;
			axiosInstance.defaults.headers["Authorization"] = "";
			return response;
		})
		.catch((err) => {
			var message;

			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = err.response.data;
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw err;
		});
};

const getList = (url) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);
	return axiosInstance
		.get(url)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			throw err[1];
		});
};

const getParamsList = (url, params) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);
	return axiosInstance
		.get(url, {
			params: params,
		})
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response.data;
		})
		.catch((err) => {
			throw err[1];
		});
};

// postForgetPwd
const postAdd = (url, data, params = {}, signal) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);

	return axiosInstance
		.post(url, data, {
			params: params,
			// signal: signal
		})
		.then((response) => {
			if (response.status === 400 || response.status === 500) {
				throw response;
			}

			return response;
		})
		.catch((err) => {
			var message;

			// if (signal.aborted) {
			// 	if (signal.reason) {
			// 		message = `Request aborted with reason: ${signal.reason}`;
			// 		return []
			// 	} else {
			// 		message = "Request aborted but no reason was given."
			// 		return []
			// 	}
			// }

			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = err.response.data;
						break;
					default:
						message = err[1];
						break;
				}
			}

			console.log(message);

			throw err;
		});
};

const postFormData = (url, data) => {
	const formData = serialize(data);

	return axiosInstance
		.post(url, formData)
		.then((response) => {
			// console.log("postFormData THEN ", response);
			if (response.status === 400 || response.status === 500) {
				throw response;
			}

			return response;
		})
		.catch((err) => {
			// console.log("postFormData erorr", err);
			var message;

			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = err.response.data;
						break;
					case 413:
						message = "File must be smaller than 20 MB";
						break;
					default:
						// message = err[1];
						message = "Unable to upload the file";
						break;
				}
			}
			return message;
		});
};
const putImg = (url, data) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);
	// const formData = new FormData();
	// formData.append("file", data.file);
	const formData = serialize(data);

	return axiosInstance
		.put(url, formData, {
			headers: {},
		})
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			//throw err[1];

			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = err.response.data;
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};
const patchImg = (url, data) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);
	// const formData = new FormData();
	// formData.append(data.name, data.file);
	const formData = serialize(data);

	return axiosInstance
		.patch(url, formData)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			//throw err[1];

			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = err.response.data;
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};
const postEdit = (url, data) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);
	// const formData = serialize(data);

	return axiosInstance
		.put(url, data)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = err.response.data;
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw err;
		});
};
const getDownloadFile = (url, params = {}) => {
	return axiosInstance
		.get(
			url,
			{
				params: params,
				headers: {
					"Access-Control-Expose-Headers": "Content-Disposition",
				},
			},
			{
				responseType: "blob",
			}
		)
		.then((response) => {
			if (response.status === 400 || response.status === 500) {
				throw response.data;
			}
			var filename = url?.split("/")[url?.split("/").length - 1];

			let headerLine = response.headers["content-disposition"];
			if (headerLine) {
				let startFileNameIndex = headerLine.indexOf('"') + 1;
				let endFileNameIndex = headerLine.lastIndexOf('"');
				filename = headerLine.substring(
					startFileNameIndex,
					endFileNameIndex
				);
			}
			fileDownload(response.data, filename);
		})
		.catch((err) => {
			var message;

			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = "Invalid credentials";
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};
const postDownloadFile = (url, body, params = {}, signal) => {
	return axiosInstance
		.post(url, body, {
			params: params,
			responseType: "blob",
			signal: signal,
		})
		.then((response) => {
			if (response.status === 400 || response.status === 500) {
				throw response.data;
			}
			var filename = "filename.csv";
			let headerLine = `${body.model_name}.csv`;
			console.log(headerLine);
			if (headerLine) {
				// let startFileNameIndex = headerLine.indexOf('"') + 1;
				// let endFileNameIndex = headerLine.lastIndexOf('"');
				// filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
				filename = headerLine;
			}
			fileDownload(response.data, filename);
		})
		.catch((err) => {
			var message;

			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = "Invalid credentials";
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};

const postDelete = (url) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);
	return axiosInstance
		.delete(url)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			//throw err;

			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = "Invalid credentials";
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};
//--------------------------
const patchEdit = (url, data) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);
	return axiosInstance
		.patch(url, data)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = err.response.data;
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};

// postForgetPwd
const postAddmultipart = (url, data) => {
	const user = localStorage.getItem("authUser");
	let sjson = JSON.parse(user);
	return axios
		.post(url, data, {
			headers: {
				Authorization: "Bearer  " + sjson.tokens.access,
				"content-type": "multipart/form-data",
			},
		})
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			//throw err[1];

			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = "Invalid credentials";
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};

const postEditmultipart = (url, data) => {
	const user = localStorage.getItem("authUser");
	let sjson = JSON.parse(user);
	return axios
		.put(url, data, {
			headers: {
				Authorization: "Token " + sjson.data.token,
				"content-type": "multipart/form-data",
			},
		})
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			//throw err[1];

			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = "Invalid credentials";
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};

const getRowData = (url) => {
	// const user = localStorage.getItem("authUser");
	// let sjson = JSON.parse(user);
	return axiosInstance
		.get(url)
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			//throw err;

			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = "Invalid credentials";
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};

const postUpload = (url, csvfile) => {
	const user = localStorage.getItem("authUser");
	let sjson = JSON.parse(user);
	// var formData = new FormData();
	// formData.append("file", csvfile.files[0]);
	return axios
		.post(url, csvfile, {
			headers: {
				Authorization: "Token " + sjson.data.token,
				"content-type": "multipart/form-data",
			},
		})
		.then((response) => {
			if (response.status === 400 || response.status === 500)
				throw response.data;
			return response;
		})
		.catch((err) => {
			//throw err;

			var message;
			if (err.response && err.response.status) {
				switch (err.response.status) {
					case 404:
						message =
							"Sorry! the page you are looking for could not be found";
						break;
					case 500:
						message =
							"Sorry! something went wrong, please contact our support team";
						break;
					case 400:
						message = "Invalid credentials";
						break;
					default:
						message = err[1];
						break;
				}
			}
			throw message;
		});
};

export {
	//casings
	toSentenceCase,
	addParams,
	simplePost,
	// VendorLogin,
	postLogin,
	postLogout,
	getList,
	getParamsList,
	postAdd,
	postFormData,
	putImg,
	patchImg,
	postEdit,
	getDownloadFile,
	postDownloadFile,
	//-----------
	patchEdit,
	postDelete,
	getRowData,
	postUpload,
	postAddmultipart,
	postEditmultipart,
};
