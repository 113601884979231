import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { startSync, syncStatus, getSyncList,getSyncLogList, syncSettingsAdd, getSyncSettings, startPriceBookSync, startSchemeSync, syncPriceBookStatus, syncSchemeStatus} from "./synchronizationActions";

const synchronizationSlice = createSlice({
  name: "sync",
  initialState: {
    loading: false,
    schemeSyncLoading:false,
    status: "",
    error: "",
    syncList: [],
    syncValue: "",
    syncParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    manualPriceSyncStatus:"",
    manualSchemesSyncStatus:"",
    syncLogList: [],
    syncLogId:"",
    syncLogParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    synLogCount: 0,
    syncTypeName: "",
    syncLogLoading: false,
    syncLoading: false,
    synCount: 0,
    syncSettingsData: {},
  },
  reducers: {
    setSyncParams: (state, action) => {
      state.syncParams = action.payload
    },
    setSyncValue: (state,action) =>{
      state.syncValue = action.payload
    },
    setSyncTypeName : (state,action)=>{
      state.syncTypeName = action.payload
    },
    setOpenModel: (state, action) => {
			return {
				...state,
				openModal: action.payload,
			};
		},
    InputChangeValue: (state, action) => {
      return {
        ...state,
        syncSettingsData: {
          ...state.syncSettingsData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    // AmcuInputChangeValue: (state, action) => {
    //   return {
    //     ...state,
    //     amcuSyncData: {
    //       ...state.amcuSyncData,
    //       [action.payload.key]: action.payload.value,
    //     },
    //   };
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startSync.pending, (state, action) => {
        state.status = "startSync loading";
        state.loading = true;
      })
      .addCase(startSync.fulfilled, (state, action) => {
        state.status = "startSync succeeded";
        state.loading = true;
      })
      .addCase(startSync.rejected, (state, action) => {
        state.status = "startSync failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(startPriceBookSync.pending, (state, action) => {
        state.status = "startPriceBookSync loading";
        state.loading = true;
      })
      .addCase(startPriceBookSync.fulfilled, (state, action) => {
        state.status = "startPriceBookSync succeeded";
        state.manualPriceSyncStatus=action.payload.message
        state.loading = false;
      })
      .addCase(startPriceBookSync.rejected, (state, action) => {
        state.status = "startPriceBookSync failed";
        state.loading = false;
        state.error = action.error.message;
      })
      



      .addCase(syncPriceBookStatus.pending, (state, action) => {
        state.status = "syncPriceBookStatus loading";
        state.loading = true;
      })
      .addCase(syncPriceBookStatus.fulfilled, (state, action) => {
        state.status = "syncPriceBookStatus succeeded";
        state.manualPriceSyncStatus=action.payload.data.message
        state.loading = false;
      })
      .addCase(syncPriceBookStatus.rejected, (state, action) => {
        state.status = "syncPriceBookStatus failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(startSchemeSync.pending, (state, action) => {
        state.status = "startSchemeSync loading";
        state.schemeSyncLoading = true;
      })
      .addCase(startSchemeSync.fulfilled, (state, action) => {
        state.status = "startSchemeSync succeeded";
        state.schemeSyncLoading = false;
      })
      .addCase(startSchemeSync.rejected, (state, action) => {
        state.status = "startSchemeSync failed";
        state.schemeSyncLoading = false;
        state.error = action.error.message;
      })

      .addCase(syncSchemeStatus.pending, (state, action) => {
        state.status = "syncSchemeStatus loading";
        state.schemeSyncLoading = true;
      })
      .addCase(syncSchemeStatus.fulfilled, (state, action) => {
        console.log("action payload: " + action.payload)
        state.status = "syncSchemeStatus succeeded";
        state.manualSchemesSyncStatus=action.payload.data.message
        state.schemeSyncLoading = false;
      })
      .addCase(syncSchemeStatus.rejected, (state, action) => {
        state.status = "syncSchemeStatus failed";
        state.schemeSyncLoading = false;
        state.error = action.error.message;
      })

      .addCase(syncStatus.pending, (state, action) => {
        state.status = "syncStatus loading";
        state.loading = true;
      })
      .addCase(syncStatus.fulfilled, (state, action) => {
        state.status = "syncStatus succeeded";
        state.loading = action.payload.loading;
      })
      .addCase(syncStatus.rejected, (state, action) => {
        state.status = "syncStatus failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSyncList.pending, (state, action) => {
        state.status = "getSyncList loading";
        state.syncLoading = true
      })      
      .addCase(getSyncList.fulfilled, (state, action) => {
        // var response = action.payload.response; 
        // state.status = "getSyncList successed";
        // state.syncLoading = false
        // var noofpages = Math.ceil(
        //   action.payload.count / state.syncParams?.page_size
        // );
        // state.syncList = response.results;
        // state.synCount = response.count;
        // state.syncParams = {
        //   ...state.syncParams,
        //   no_of_pages: noofpages,
        // };

        state.status = "getSyncList succeeded";
        state.syncLoading = false;
        var noofpages = Math.ceil(
          action.payload.count / state.syncParams.page_size
        );
        state.synCount = action.payload.count;
        state.syncList = action.payload.results;
        state.syncParams = {
          ...state.syncParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getSyncList.rejected, (state, action) => {
        state.status = "getSyncList rejected";
        state.syncLoading = false
        state.error = action.error.message
      })
      .addCase(getSyncLogList.pending, (state, action) => {
        state.status = "getSyncLogList loading";
        state.syncLogLoading = true
      })
      .addCase(getSyncLogList.rejected, (state, action) => {
        state.status = "sync Log Status failed";
        state.syncLogLoading = false;
        state.error = action.error.message;
      })
      .addCase(getSyncLogList.fulfilled, (state, action) => {
        state.status = "sync Log Status succeeded";
        state.syncLogLoading = false;
        var noofpages = Math.ceil(
          action.payload.count / state.syncLogParams.page_size
        );
        state.synLogCount = action.payload.count;
        state.syncLogList = action.payload.results;
        state.syncLogParams = {
          ...state.syncLogParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getSyncSettings.pending, (state, action) => {
        state.loading = true;
        state.status = "getSyncSettings pending";
      })
      .addCase(getSyncSettings.fulfilled, (state, action) => {
        state.status = "getSyncSettings succeeded";
        state.loading = false;
        state.syncSettingsData = {
          focus_username: action.payload.focus_username,
          focus_password: action.payload.focus_password,
          focus_base_url: action.payload.focus_base_url,
          focus_companycode: action.payload.focus_companycode,
          focus_sync_on: action.payload.focus_sync_on,
          amcu_username: action.payload.amcu_username,
          amcu_password: action.payload.amcu_password,
          amcu_baseurl: action.payload.amcu_baseurl,
          amcu_api_key: action.payload.amcu_api_key,
          amcu_sync_on: action.payload.amcu_sync_on,
          enable_auto_sync: action.payload.enable_auto_sync,
          amcu_enable_auto_sync: action.payload.amcu_enable_auto_sync,
        }
      })
      .addCase(getSyncSettings.rejected, (state, action) => {
        state.status = "getSyncSettings failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(syncSettingsAdd.pending, (state, action) => {
        state.loading = true;
        state.status = "SyncSettingsAdd pending";
      })
      .addCase(syncSettingsAdd.fulfilled, (state, action) => {
        state.status = "SyncSettingsAdd succeeded";
        state.loading = false;
      })
      .addCase(syncSettingsAdd.rejected, (state, action) => {
        state.status = "SyncSettingsAdd failed";
        state.loading = false;
        state.error = action.error.message;
      })
  }
});

// Action creators are generated for each case reducer function
export const {
  setSyncParams,
  setSyncValue,
  setOpenModel,
  setSyncTypeName,
  InputChangeValue,
} = synchronizationSlice.actions;

export default synchronizationSlice.reducer;
