import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    getList,
    getParamsList,
    addParams,
    postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
    RiAlertLine,RiCheckboxCircleLine
} from "react-icons/ri";
import Swal from "sweetalert2";


const delay = (ms) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
async function checkStatus(payload) {
    const { dispatch } = payload;
    let timeout;
    timeout = await delay(3000);
    dispatch(syncStatus(payload));
}
export const startSync = createAsyncThunk(
    "/startSync",
    async (payload, { dispatch }) => {
        const { label, name, requestUrl, statusUrl } = payload;
        try {
            const response = await getList(requestUrl);
            if (response.data) {
                dispatch(syncStatus(payload))
                return response.data
            } else {
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);

export const syncStatus = createAsyncThunk(
    "/syncStatus",
    async (payload, { dispatch }) => {
        const { label, name, requestUrl, statusUrl } = payload;
        try {
            const response = await getList(statusUrl);
            if (!response.data.status) {
                Swal.fire({
                    title: "Success",
                    text: `${label} Synchronization Completed`,
                    icon: "success",
                    confirmButtonColor: "#3085d6",
                    // confirmButtonText: "Cool",
                });
                return { loading: false };
            } else {
                checkStatus({ ...payload, dispatch });
                return { loading: true }
            }
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "Error while synchronizing data",
                icon: "error",
                confirmButtonColor: "#3085d6",
                // confirmButtonText: "Cool",
            });
            return error.message;
        }
    }
);

 
export const startPriceBookSync = createAsyncThunk(
    "/startPriceBookSync",
    async (payload, { dispatch }) => {
        const { label, name, requestUrl, statusUrl } = payload;
        try {
            const response = await getList(requestUrl);
            if (response.data) {
                dispatch(syncPriceBookStatus(payload))
                return response.data
            } else {
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);

export const syncPriceBookStatus = createAsyncThunk(
    "/syncPriceBookStatus",
    async (payload, { dispatch }) => {

        const { label, name, requestUrl, statusUrl } = payload;
        try {
            const response = await getList(statusUrl);
            if (!response.data.status) {
                Swal.fire({
                    title: "Success",
                    text: `${response?.data?.message} `,
                    icon: "success",
                    confirmButtonColor: "#3085d6",
                    // confirmButtonText: "Cool",
                });
                return response;
            } else {
                checkStatus({ ...payload, dispatch });
                return { loading: true }
            }
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "Error while synchronizing data",
                icon: "error",
                confirmButtonColor: "#3085d6",
                // confirmButtonText: "Cool",
            });
            return error.message;
        }
    }
);

export const startSchemeSync = createAsyncThunk(
    "/startSchemeSync",
    async (payload, { dispatch }) => {
        const { label, name, requestUrl, statusUrl } = payload;
        try {
            const response = await getList(requestUrl);
            if (response.data) {
                dispatch(syncSchemeStatus(payload))
                return response.data
            } else {
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);

export const syncSchemeStatus = createAsyncThunk(
    "/syncSchemeStatus",
    async (payload, { dispatch }) => {
        const { label, name, requestUrl, statusUrl } = payload;
        try {
            const response = await getList(statusUrl);
            if (!response.data.status) {
                Swal.fire({
                    title: "Success",
                    text: `${response?.data?.message} `,
                    icon: "success",
                    confirmButtonColor: "#3085d6",
                    // confirmButtonText: "Cool",
                });
                return response
            } else {
                checkStatus({ ...payload, dispatch });
                return { loading: true }
            }
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "Error while synchronizing data",
                icon: "error",
                confirmButtonColor: "#3085d6",
                // confirmButtonText: "Cool",
            });
            return error.message;
        }
    }
);


// export const getSyncList = createAsyncThunk("/getSyncList", async(payload) => {
//     const { params } = payload;
//     try {
//         const response = await getParamsList("/thirdparty/sync_trigger/list/", params);
//         if(response.status === 200){
//             return {response}
//         }else{
//             throw new Error(response);
//         }
//     }catch(error){
//         console.log("errrr", error)
//         throw error.message
//     }
// })


export const getSyncList = createAsyncThunk("/getSyncList",
    async (payload) => {
        const params = addParams(payload);
        try {
          const response = await getParamsList("/thirdparty/sync_trigger/list/", params);
          if (response) {
            return  response;
          } else {
            throw new Error(response);
          }
        } catch (error) {
          throw error.message;
        }
    }
  );

  export const getSyncLogList = createAsyncThunk("/getSyncLogList",
    async (payload) => {
        try {
          const response = await getParamsList("/thirdparty/sync_log/list/", payload.params);
          if (response) {
            return  response;
          } else {
            throw new Error(response);
          }
        } catch (error) {
          throw error.message;
        }
    }
  );

// export const startAmcuSync = createAsyncThunk(
//     "/startAmcuSync",
//     async () => {
//         try {
//             const response = await getList("/thirdparty/amcu_data_collection/");
//             if (response.data) {
//                 return response.data
//             } else {
//                 throw new Error(response);
//             }
//         } catch (error) {
//             Modal.error({
//                 icon: (
//                     <span className="remix-icon">
//                         <RiAlertLine />
//                     </span>
//                 ),

//                 title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//                 content: (
//                     <div>
//                         <p className="hp-p1-body hp-text-color-black-80">
//                             Sorry! Getting from server side issue!
//                         </p>
//                     </div>
//                 ),
//             });
//             return error.message;
//         }
//     }
// );

// export const amcuSyncStatus = createAsyncThunk(
//     "/amcuSyncStatus",
//     async (payload, { dispatch }) => {
//         try {
//             const response = await getParamsList(
//                 `/thirdparty/amcu_data_collection/status/`,
//                 payload
//             );
//             console.log(response);
//             if (!response.status) {
//                 Swal.fire({
//                     title: "Success",
//                     text: "Process Completed",
//                     icon: "success",
//                     confirmButtonColor: "#3085d6",
//                     // confirmButtonText: "Cool",
//                 });
//                 return response;
//             } else {
//                 checkStatus({ dispatch, thunk: "syncStatus", });
//             }
//         } catch (error) {
//             Swal.fire({
//                 title: "Error",
//                 text: "Error while processing",
//                 icon: "error",
//                 confirmButtonColor: "#3085d6",
//                 // confirmButtonText: "Cool",
//             });
//             return error.message;
//         }
//     }
// );
export const getSyncSettings = createAsyncThunk(
    "/getSyncSettings",
    async (payload) => {
        try {
            console.log("payload",payload);
            const response = await getList("/system/globalvariables/sync/jsondata/");
            if (response.data) {
                return response.data
            } else {
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting error from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);

export const syncSettingsAdd = createAsyncThunk(
    "/syncSettingsAdd",
    async (payload) => {
        try {
            const response = await postEdit("/system/globalvariables/sync/jsondata/", payload);
            if (response.status === 200) {
                Modal.success({
                    icon: (
                        <span className="remix-icon">
                            <RiCheckboxCircleLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Your Sync Settings Updated Successfully.!
                            </p>
                        </div>
                    ),
                });
            } else {
                Modal.error({
                    icon: (
                        <span className="remix-icon">
                            <RiAlertLine />
                        </span>
                    ),
                    title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                    content: (
                        <div>
                            <p className="hp-p1-body hp-text-color-black-80">
                                Sorry! Unable to Updated Focus Settings. Please try again!
                            </p>
                        </div>
                    ),
                });
                throw new Error(response);
            }
        } catch (error) {
            Modal.error({
                icon: (
                    <span className="remix-icon">
                        <RiAlertLine />
                    </span>
                ),

                title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
                content: (
                    <div>
                        <p className="hp-p1-body hp-text-color-black-80">
                            Sorry! Getting from server side issue!
                        </p>
                    </div>
                ),
            });
            return error.message;
        }
    }
);