import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	addParams,
	getList,
	getParamsList,
	postAdd,
	postEdit,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";
import { inputChangeValue } from "./retailerIndents.slice";

export const getRetailerIndents = createAsyncThunk(
	"/getRetailerIndents",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/orders/retailer/indentslist/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
export const getSaleAgent = createAsyncThunk(
	"/getSaleAgent",
	async (payload, { dispatch, getState, rejectWithValue }) => {
	  
	  const state = getState().yourSlice; // Adjust based on your slice name
	  const params = addParams(payload);
  
	  try {
		const response = await getParamsList(
		  `/users/sale_agents/${payload.distributor_id}/`,
		  params
		);
		if (response && response.sale_agents && response.sale_agents.length > 0) {
		  const changeData = {
			saleAgent_id: response?.sale_agents[0]?.id,
			saleAgent: response?.sale_agents[0],
		  };
  
		  // Dispatch the `inputChangeValue` action
		  dispatch(inputChangeValue(changeData));
  
		  // Return the response for further processing
		  return response;
		} else {
			return response;
		  // Throwing an error if `response` is not as expected
		  throw new Error("Invalid response or no sale agents found.");
		}
	  } catch (error) {
		console.error("Error in getSaleAgent:", error);
		
		// Use `rejectWithValue` to pass error to the `rejected` case
		return rejectWithValue(error.message || "An error occurred.");
	  }
	}
  );
  
export const postRetailerIndents = createAsyncThunk(
	"/postRetailerIndents",
	async (payload) => {
		try {
			const { data, history, form } = payload;
			const response = await postAdd(
				"/orders/retailer/indentscreate/",
				data
			);
			if (response.status === 201) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Retailer Indent Added Successfully.!
							</p>
						</div>
					),
				});
				history.push("/retailer_indents");
				form.resetFields();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			// if (error.response) {
			// 	Modal.error({
			// 		icon: (
			// 			<span className="remix-icon">
			// 				<RiAlertLine />
			// 			</span>
			// 		),
			// 		title: (
			// 			<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
			// 		),
			// 		content: (
			// 			<div>
			// 				<p className="hp-p1-body hp-text-color-black-80">
			// 					Sorry! Unable to add Retailer Indent. Please try
			// 					again!
			// 				</p>
			// 			</div>
			// 		),
			// 	});
			// } else {
			// 	Modal.error({
			// 		icon: (
			// 			<span className="remix-icon">
			// 				<RiAlertLine />
			// 			</span>
			// 		),

			// 		title: (
			// 			<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
			// 		),
			// 		content: (
			// 			<div>
			// 				<p className="hp-p1-body hp-text-color-black-80">
			// 					Sorry! Getting from server side issue!
			// 				</p>
			// 			</div>
			// 		),
			// 	});
			// }
			const errors = error.response;

			console.log(errors);
			Modal.error({
				icon: (
					<span className="remix-icon">
						<RiAlertLine />
					</span>
				),

				title: (
					<h5 className="hp-mb-0 hp-font-weight-500">
						{errors.statusText}
					</h5>
				),
				content: (
					<div>
						<p className="hp-p1-body hp-text-color-black-80">
							{JSON.stringify(errors.data)}
						</p>
					</div>
				),
			});

			throw error.message;
		}
	}
);
export const editRetailerIndents = createAsyncThunk(
	"/editRetailerIndents",
	async (payload) => {
		try {
			const { id, data, history, form } = payload;
			const response = await postEdit(
				`/orders/retailer/indentsdetail/${id}`,
				data
			);
			if (response.status === 200) {
				Modal.success({
					icon: (
						<span className="remix-icon">
							<RiCheckboxCircleLine />
						</span>
					),
					title: (
						<h5 className="hp-mb-0 hp-font-weight-500">Success</h5>
					),
					content: (
						<div>
							<p className="hp-p1-body hp-text-color-black-80">
								Retailer Indent Edited Successfully.!
							</p>
						</div>
					),
				});
				history.push("/retailer_indents");
				form.resetFields();
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			// if (error.response) {
			// 	Modal.error({
			// 		icon: (
			// 			<span className="remix-icon">
			// 				<RiAlertLine />
			// 			</span>
			// 		),
			// 		title: (
			// 			<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
			// 		),
			// 		content: (
			// 			<div>
			// 				<p className="hp-p1-body hp-text-color-black-80">
			// 					Sorry! Unable to edit Retailer Indent. Please
			// 					try again!
			// 				</p>
			// 			</div>
			// 		),
			// 	});
			// } else {
			// 	Modal.error({
			// 		icon: (
			// 			<span className="remix-icon">
			// 				<RiAlertLine />
			// 			</span>
			// 		),

			// 		title: (
			// 			<h5 className="hp-mb-0 hp-font-weight-500">Error</h5>
			// 		),
			// 		content: (
			// 			<div>
			// 				<p className="hp-p1-body hp-text-color-black-80">
			// 					Sorry! Getting from server side issue!
			// 				</p>
			// 			</div>
			// 		),
			// 	});
			// }
			const errors = Object.values(error?.response?.data);

			console.log(errors);

			Modal.error({
				icon: (
					<span className="remix-icon">
						<RiAlertLine />
					</span>
				),

				title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
				content: (
					<div>
						<p className="hp-p1-body hp-text-color-black-80">
							{errors.length != 0 && Array.isArray(errors)
								? `${errors?.flat()[0]}`
								: `Sorry! Getting from server side issue!`}
						</p>
					</div>
				),
			});
			throw error.message;
		}
	}
);
export const getRetailerIndentByID = createAsyncThunk(
	"/getRetailerIndentByID",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getList(
				`/orders/retailer/indentsdetail/${params.id}`
			);
			if (response) {
				return response.data;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);
